import FaceBook from 'public/assets/icons/account/Facebook.svg';
import { default as Mail } from 'public/assets/icons/account/mail.svg';
import Twitter1 from 'public/assets/icons/account/twitter.svg';
import AppleMusic from 'public/assets/icons/applemusic.svg';
import BandCamp from 'public/assets/icons/bandcamp.svg';
import Facebook from 'public/assets/icons/facebook.svg';
import Instagram from 'public/assets/icons/instagram.svg';
import Website from 'public/assets/icons/personal-website.svg';
import Soundcloud from 'public/assets/icons/soundcloud.svg';
import Spotify from 'public/assets/icons/spotify.svg';
import TikTok from 'public/assets/icons/tiktok.svg';
import Youtube from 'public/assets/icons/youtube.svg';
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import { IMAGES_PREFIX } from '~config/config-global';
import { getId } from '~helper/index';
import { orderPath } from './paths';
const orderDetails: any = {
  "Song Details": [
  // {
  //     _id: getId(),
  //     name: "Gifter's name",
  //     value: "[user.firstName] [user.lastName]"
  //  },
  // {
  //     _id: getId(),
  //     name: "Gifter’s email",
  //     value: "[user.role.name]==Visitor?[user.gifterEmail]:[user.email]"
  //  },
  {
    _id: getId(),
    name: "Recipient",
    value: "[orderRecipient.name]"
  },
  // {
  //     _id: getId(),
  //     name: "Name Pronunciation",
  //     value: "[orderRecipient.pronounce]"
  // },
  // {
  //     _id: getId(),
  //     name: "Name Pronunciation File",
  //     value: "file [orderRecipient.media.path]"
  // },
  {
    _id: getId(),
    name: "Relationship to you",
    value: "[orderRecipientRelationship.recipientRelationship.name]"
  }, {
    _id: getId(),
    name: "Name mentioned?",
    value: "bool [mentionRecipientInSong]"
  }, {
    _id: getId(),
    name: "Occasion",
    value: "[orderOccasion.occasion.name]"
  }, {
    _id: getId(),
    name: "Occasion mentioned?",
    value: "bool [mentionOccasionInSong]"
  }],
  "Artist": [{
    _id: getId(),
    name: "Preferred artist",
    value: '[orderArtists.0.user.stageName]'
  }, {
    _id: getId(),
    name: "Genre",
    value: '[orderMusicTraits.where(musicTrait>traitType*0*musicTrait>name)]'
  }, {
    _id: getId(),
    name: "Beat",
    value: '[orderMusicTraits.where(musicTrait>traitType*1*musicTrait>name)]'
  }, {
    _id: getId(),
    name: "Feel",
    value: '[orderMusicTraits.where(musicTrait>traitType*2*musicTrait>name)]'
  }
  // {
  //     _id: getId(),
  //     name: "Preferred artist 2",
  //     value: '[orderArtists.1.user.stageName]'
  // },
  ],
  "Story": [{
    name: "Tell your artist about the recipient",
    value: '[aboutSong]',
    valueSx: {
      fontSize: '15px',
      color: "#637381"
    }
  }]
};
const artistsCardsHeights = ["488px", "662px", "468px", "600px", "507px", "597px", "552px", "467px", "555px"];
const socialIcons: any = {
  Facebook,
  Instagram,
  Youtube,
  AppleMusic,
  Soundcloud,
  Spotify,
  TikTok,
  BandCamp,
  Website
};
const orderSteps = [{
  label: "Starter",
  path: `${orderPath}step1/for-who/`
}, {
  label: "Artist",
  artistSelectedPath: `${orderPath}step2/pick-your-artist/`,
  aisongSelectedPath: `${orderPath}step2/select-gender-ai/`
}, {
  label: "Story",
  path: `${orderPath}step3/topics/`
}, {
  label: "Review",
  path: `${orderPath}step4/review/`
}];
const orderDetailSteps = [{
  id: 1,
  label: 'Order Submission',
  actualStatus: ["Draft"]
}, {
  id: 2,
  label: 'Order Review',
  actualStatus: ["Pending"]
}, {
  id: 3,
  label: 'Assigned to Artist',
  actualStatus: ["InProduction"]
}, {
  id: 5,
  label: 'In Production',
  actualStatus: ["InProduction"]
}, {
  id: 6,
  label: 'Song Review',
  actualStatus: ["InReview", "Revision"]
}, {
  id: 7,
  label: 'Done & Delivered',
  actualStatus: ["Approved", "Complete"]
}];
const songDetails: any = [{
  _id: getId(),
  name: "Custom Song",
  value: "[orderSong.song.name]"
}, {
  _id: getId(),
  name: "Order Date",
  value: "[createdAt.format(DD/MM/YY)]"
}, {
  _id: getId(),
  name: "Status",
  value: "[orderStatus.enum(orderStatuses)] on [statusChangedOn.format(DD/MM/YY)]"
}, {
  _id: getId(),
  name: "Delivery Date",
  value: "[deliveryDate.format(DD/MM/YY)]"
}];
const orderTableLabels: any = [{
  id: 'action',
  label: ''
}, {
  id: 'shortid',
  label: 'Order No'
}, {
  id: 'songName',
  label: 'Song Name'
}, {
  id: 'date',
  label: 'Order Date'
}, {
  id: 'status',
  label: 'Status'
}, {
  id: ''
}];
const addonsTableLabels: any = [{
  id: 'name',
  label: 'Name'
}, {
  id: 'orderDate',
  label: 'Order Date'
}, {
  id: 'status',
  label: 'Status'
}, {
  id: 'file',
  label: ''
}];
const socialLinks = [{
  Button: FacebookShareButton,
  type: 'facebook',
  icon: <FaceBook width={36} height={36} />
}, {
  Button: TwitterShareButton,
  type: 'twitter',
  icon: <Twitter1 width={36} height={36} />
}, {
  Button: EmailShareButton,
  type: 'mail',
  icon: <Mail width={36} height={36} />
}];
const tipAmounts = [{
  value: 10,
  label: "10$"
}, {
  value: 20,
  label: "20$"
}, {
  value: 30,
  label: "30$"
}, {
  value: 40,
  label: "40$"
}, {
  value: 50,
  label: "50$"
}, {
  value: 60,
  label: "60$"
}, {
  value: 70,
  label: "70$"
}, {
  value: 80,
  label: "80$"
}, {
  value: 90,
  label: "90$"
}, {
  value: 100,
  label: "100$"
}, {
  value: 0,
  label: "Custom"
}];
const ccIcons = [IMAGES_PREFIX + '/assets/icons/visa.svg', IMAGES_PREFIX + '/assets/icons/mastercard.svg', IMAGES_PREFIX + '/assets/icons/amex.png', IMAGES_PREFIX + '/assets/icons/discover.png', IMAGES_PREFIX + '/assets/icons/diners.png', IMAGES_PREFIX + '/assets/icons/jcb.png', IMAGES_PREFIX + '/assets/icons/maestro.svg', IMAGES_PREFIX + '/assets/icons/leumicard.jpeg', IMAGES_PREFIX + '/assets/icons/isracard.png', ''];
const footerLinks = [{
  headline: 'Company',
  children: [{
    name: 'About us',
    href: '/about-us'
  }, {
    name: 'Support',
    href: 'https://support.songoven.com/portal/en/kb/songoven'
  }, {
    name: 'Privacy Notice',
    href: '/privacy-policy'
  }, {
    name: 'Terms of use',
    href: '/terms-of-use'
  }, {
    name: 'Contact',
    href: 'https://support.songoven.com/portal/en/newticket?departmentId=817310000000006907&layoutId=817310000000074011',
    target: '_blank'
  }]
}, {
  headline: 'Production',
  children: [{
    name: 'Artists',
    href: '/artists'
  }, {
    name: 'Song Samples',
    href: '/occasions'
  }]
}, {
  headline: 'For Artist',
  children: [{
    name: 'Artist Application',
    href: 'https://custom.songoven.com/artist-apllication'
  }, {
    name: 'Login',
    href: 'https://artist.songoven.com',
    target: '_blank'
  }]
}];
const addonImages = [{
  type: 0,
  image: IMAGES_PREFIX + '/assets/images/addons/playback.png'
}, {
  type: 1,
  image: IMAGES_PREFIX + '/assets/images/addons/additional-verse.png'
}, {
  type: 2,
  image: IMAGES_PREFIX + '/assets/images/addons/artist-tip.png'
}, {
  type: 3,
  image: IMAGES_PREFIX + '/assets/images/addons/audio-greeting.png'
}, {
  type: 4,
  image: IMAGES_PREFIX + '/assets/images/addons/lyrics-sheet.png'
}];
const wayWeDoSteps = [{
  id: 1,
  label: 'Select Artist',
  details: 'Choose an artist from our selection and define the genre and mood you want for your song.'
}, {
  id: 2,
  label: 'Share story',
  details: 'Tell us about the song recipient and your relationship: shared experiences, funny moments, what best describes them etc.'
}, {
  id: 3,
  label: 'Song Production',
  details: 'Your artist will craft a one a one-of-a-kind song that will capture your taste based on the ingredients you have provided.'
}];
const stepsMini: any = [{
  id: getId(),
  image: IMAGES_PREFIX + '/assets/images/home/anniverarysong.jpg',
  youtubeURL: 'https://www.youtube.com/watch?v=Udo4Oe1H8cE'
}, {
  id: getId(),
  image: IMAGES_PREFIX + '/assets/images/home/talia5.jpg',
  youtubeURL: 'https://www.youtube.com/watch?v=-2skO_7c4To'
}, {
  id: getId(),
  image: IMAGES_PREFIX + '/assets/images/home/ofir3.jpg',
  youtubeURL: 'https://www.youtube.com/shorts/HeG9qVmi4c8'
}, {
  id: getId(),
  image: IMAGES_PREFIX + '/assets/images/home/adi.jpg',
  youtubeURL: 'https://www.youtube.com/watch?v=HGU5XlarYMo'
}, {
  id: getId(),
  image: IMAGES_PREFIX + '/assets/images/home/keith.jpg',
  youtubeURL: 'https://www.youtube.com/shorts/mLDhk2pjvMc'
}];
export { ccIcons, stepsMini, tipAmounts, addonImages, orderSteps, footerLinks, socialIcons, songDetails, socialLinks, wayWeDoSteps, orderDetails, orderDetailSteps, orderTableLabels, addonsTableLabels, artistsCardsHeights };